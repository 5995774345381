import { partners } from 'constants/index';
import HeadingWithDecor from 'components/HeadingWithDecor/HeadingWithDecor';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

function Partners() {
  const { t } = useTranslation();

  const getItem = (partner, index) => {
    if (partner.link) {
      return (
        <NavLink to={partner.link} key={partner.id} className={`${index < 3 ? 'col-span-1 lg:col-span-4' : 'col-span-1 lg:col-span-3 '} hover:shadow-xl flex items-center justify-center py-3.5 px-3.5 md:px-8 transition-all duration-200 grayscale hover:grayscale-0`}>
          <img src={partner.image} alt={`partner ${index}`} />
        </NavLink>
      );
    }

    return (
      <span key={partner.id} className={`${index < 3 ? 'col-span-1 lg:col-span-4' : 'col-span-1 lg:col-span-3 '} hover:shadow-xl flex items-center justify-center py-3.5 px-3.5 md:px-8 transition-all duration-200 grayscale hover:grayscale-0`}>
        <img src={partner.image} alt={`partner ${index}`} />
      </span>
    );
  };

  return (
    <section id="action-page-partners" className="bg-white py-24">
      <div className="container px-3.5">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-5">
          <div className="col-span-3 flex flex-wrap items-center mb-10 lg:mb-0">
            <HeadingWithDecor title={t('actionPage.partners.title')} />
          </div>
          <div className="col-span-9">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 gap-x-5 gap-y-10 justify-center">
              {partners.map(getItem)}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partners;
