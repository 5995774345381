import { COLOR_VARIABLES } from 'constants/index';
import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';
import CircleButton from 'components/_domains/ActionPage/CircleButton/CircleButton';
import { ReactComponent as VideoIcon } from 'assets/images/video-icon.svg';
import { ReactComponent as BackIcon } from 'assets/images/arrow-left-white.svg';
import { useTranslation } from 'react-i18next';
import VideoItem from 'components/_domains/ActionPage/VideoItem/VideoItem';

function DropdownAccordion({
  dropdownData, handleCloseElement, setCurrentVideoSrc,
}) {
  const [animation, setAnimation] = useState(false);
  const {
    accentColor: color, dropdown: {
      items, title, videoTitle, videoSrc,
    },
  } = dropdownData;

  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [currentChildOpen, setCurrentChildOpen] = useState(null);

  const COLORS = {
    [COLOR_VARIABLES.green]: {
      bg: 'bg-action-green',
      hover: 'bg-[#bee5d0]',
      border: 'border-b-action-green',
      text: 'text-action-green',
    },
    [COLOR_VARIABLES.yellow]: {
      bg: 'bg-action-yellow',
      hover: 'bg-[#ffebb6]',
      border: 'border-b-action-yellow',
      text: 'text-action-yellow',
    },
    [COLOR_VARIABLES.blue]: {
      bg: 'bg-action-blue',
      hover: 'bg-[#c4e6e8]',
      border: 'border-b-action-blue',
      text: 'text-action-blue',
    },
    [COLOR_VARIABLES.orange]: {
      bg: 'bg-action-orange',
      hover: 'bg-[#f7d0af]',
      border: 'border-b-action-orange',
      text: 'text-action-orange',
    },
    [COLOR_VARIABLES.red]: {
      bg: 'bg-action-red',
      hover: 'bg-[#ffb2b2]',
      border: 'border-b-action-red',
      text: 'text-action-red',
    },
    [COLOR_VARIABLES.pink]: {
      bg: 'bg-action-onyx',
      hover: 'bg-action-onyx bg-opacity-50',
      border: 'border-b-action-onyx',
      text: 'text-action-onyx',
    },
  };

  useEffect(() => () => {
    setAnimation(false);
    setOpen(false);
  }, [dropdownData]);

  if (!animation) {
    setTimeout(() => {
      setAnimation(true);
    }, 1);

    return '';
  }

  const handleItem = (itemData) => {
    if (currentChildOpen === itemData.label) {
      setCurrentChildOpen(null);
      setCurrentVideoSrc(null);

      return;
    }
    setCurrentChildOpen(itemData.label);
    setCurrentVideoSrc(itemData.videoSrc);
  };

  return (
    <div className="flex flex-wrap gap-2.5 w-full relative animation-fade animation-zoom">
      <button
        type="button"
        className={`${COLORS[color].bg} relative px-3.5 py-5 w-full flex flex-wrap items-center justify-between shadow-md cursor-default lg:cursor-pointer`}
        onClick={() => {
          if (items.length !== 0) setOpen((prevState) => !prevState);
          setCurrentVideoSrc(dropdownData.videoSrc);
        }}
      >
        <button
          type="button"
          onClick={handleCloseElement}
          className="block lg:hidden mb-4 w-12 h-12"
        >
          <BackIcon className="w-9 h-9" />
        </button>
        <span className="text-[28px] lg:text-base text-white uppercase mb-2.5 lg:mb-0 block text-left w-full lg:w-auto">
          {t(title)}
        </span>
        {items.length !== 0 && (
          <div className="hidden lg:block">
            <CircleButton color={COLORS[color].text} size="small" isOpen={isOpen} />
          </div>
        )}
        <div className={`hidden lg:block h-0 w-0 border-x-[16px] border-x-transparent border-b-[16px] ${COLORS[color].border} absolute top-1/2 -right-[20px] transform -translate-y-1/2 rotate-90`} />
        <div className="lg:hidden w-full">
          <VideoItem videoSrc={videoSrc} title={videoTitle} />
        </div>
      </button>
      <div className={`${isOpen ? 'flex' : 'flex lg:hidden'} flex flex-wrap w-full px-3.5 lg:px-0 gap-2.5`}>
        {items.length !== 0 && items.map((item) => (
          <div className="bg-white w-full shadow-md" key={item.label}>
            <button
              key={item.label}
              type="button"
              className="w-full"
              onClick={() => handleItem(item)}
            >
              <div className={`${COLORS[color].hover} hover:underline underline-offset-4 px-3.5 text-sm text-action-onyx py-5 flex w-full justify-between items-center text-left`}>
                {t(item.label)}
                <VideoIcon className="ml-3 w-[18px] min-w-[18px]" />
              </div>
            </button>
            <div className="block lg:hidden">
              {currentChildOpen === item.label && (
                <VideoItem videoSrc={item.videoSrc} />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DropdownAccordion;

DropdownAccordion.propTypes = {
  dropdownData: PropTypes.instanceOf(Object).isRequired,
  handleCloseElement: PropTypes.func.isRequired,
  setCurrentVideoSrc: PropTypes.func.isRequired,
};
