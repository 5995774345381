import { navigationConstant } from 'constants/index';
import NavigationItem from 'components/Navigation/NavigationItem';
import { useState } from 'react';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';

function Navigation() {
  const [isOpen, setOpen] = useState(false);
  return (
    <nav className="flex flex-wrap items-center">
      <ul className={`flex flex-wrap gap-10 absolute md:relative top-full md:top-auto inset-x-0 md:inset-x-auto bg-white p-3.5 md:p-0 ${isOpen ? '' : 'hidden md:flex'}`}>
        {navigationConstant.map((navItem) => (
          <NavigationItem key={navItem.id} id={navItem.id} label={navItem.label} path={navItem.path} items={navItem.items} />
        ))}
      </ul>
      <button
        type="button"
        className="w-6 h-6 bg-white flex flex-wrap flex-row justify-between items-center block md:hidden"
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <span className="h-0.5 rounded w-full bg-action-onyx block" />
        <span className="h-0.5 rounded w-full bg-action-onyx block" />
        <span className="h-0.5 rounded w-full bg-action-onyx block" />
      </button>
      <LanguageSwitcher />
    </nav>
  );
}

export default Navigation;
