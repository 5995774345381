import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDownBlack } from 'assets/images/arrow-down-black.svg';
import SubMenu from 'components/Navigation/SubMenu';

function NavigationItem({
  label, path, items,
}) {
  const hasItems = items.length !== 0;
  const { t } = useTranslation();

  return (
    <li className="w-full md:w-auto group relative py-2.5 underline flex flex-wrap gap-2 items-center justify-between">
      <NavLink
        to={path}
        className={({ isActive }) => `${isActive ? 'text-action-dark-blue' : 'text-action-onyx'} uppercase`}
      >
        {t(label)}
      </NavLink>
      {hasItems && (<ArrowDownBlack />)}
      {hasItems && (
        <SubMenu items={items} />
      )}
    </li>
  );
}

export default NavigationItem;

NavigationItem.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(Object),
};

NavigationItem.defaultProps = {
  items: [],
};
