import PropTypes from 'prop-types';

function HeadingWithDecor({
  title, decorColor, headingColor,
}) {
  return (
    <div className="relative">
      <div className={`${decorColor} h-1 w-28 mb-5`} />
      <h1 className={`font-medium ${headingColor} w-full uppercase text-5xl`}>
        {title}
      </h1>
    </div>
  );
}

export default HeadingWithDecor;

HeadingWithDecor.propTypes = {
  title: PropTypes.string.isRequired,
  decorColor: PropTypes.oneOf(['bg-action-pink', 'bg-white']),
  headingColor: PropTypes.oneOf(['text-action-onyx', 'text-white']),
};

HeadingWithDecor.defaultProps = {
  decorColor: 'bg-action-pink',
  headingColor: 'text-action-onyx',
};
