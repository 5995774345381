import { ReactComponent as SubscribeIcon } from 'assets/images/subscribe-icon-pink.svg';
import PropTypes from 'prop-types';

function Button({
  type, children, onClick,
}) {
  return (
    <button
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      className="cursor-pointer text-sm text-action-pink font-bold flex flex-wrap justify-between items-center bg-white py-4 px-3 capitalize min-w-[120px]"
      onClick={onClick}
    >
      {children}
      <SubscribeIcon />
    </button>
  );
}

export default Button;

Button.propTypes = {
  type: PropTypes.oneOf(['submit', 'button']),
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  type: 'button',
  onClick: () => {},
};
