export const navigationConstant = [
  {
    label: 'navigation.home',
    path: '',
    items: [],
    id: 'mNqRJEapW19Dgclp',
  },
  {
    label: 'navigation.aboutUs',
    path: 'https://govsport.eu/about-us/',
    items: [
      {
        label: 'navigation.team',
        path: 'https://govsport.eu/team/',
        id: 'K9FFu1a2G2YE7bc2',
      },
    ],
    id: 'emKo3Pn64sbMv9ve',
  },
  {
    label: 'navigation.projects',
    path: 'https://govsport.eu/projects/',
    items: [],
    id: 'Oy7ADMl8n690l41r',
  },
  {
    label: 'navigation.blog',
    path: 'https://govsport.eu/category/en-blog/',
    items: [],
    id: 'opaN4efg4jt1e29d',
  },
  {
    label: 'navigation.contact',
    path: 'https://govsport.eu/contact/',
    items: [],
    id: 'YdkyJyGNpRSy59Bn',
  },
];
