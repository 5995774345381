import { NavLink } from 'react-router-dom';

function Logo() {
  return (
    <NavLink to="/">
      <svg xmlns="http://www.w3.org/2000/svg" width="162" height="50" viewBox="0 0 162 50" fill="none">
        <path d="M1.68506 33.7858V20.2715H3.96695V33.7858H1.68506Z" fill="#404040" />
        <path d="M27.7028 33.7857H24.9101L18.0501 23.0192H17.9737L18.0214 23.6213C18.1121 24.7682 18.1551 25.8148 18.1551 26.7658V33.7905H16.0833V20.2762H18.8473L25.6882 30.9902H25.7455C25.7312 30.8468 25.7073 30.3307 25.6691 29.4418C25.6309 28.553 25.6118 27.8553 25.6118 27.3583V20.2762H27.7028V33.7905V33.7857Z" fill="#404040" />
        <path d="M47.3897 30.1252C47.3897 31.3295 46.941 32.2757 46.0435 32.959C45.146 33.6424 43.9096 33.9865 42.3342 33.9865C40.7589 33.9865 39.4652 33.7475 38.4626 33.2744V31.1813C39.0976 31.4728 39.7755 31.7022 40.4915 31.8647C41.2076 32.0319 41.8712 32.1132 42.4918 32.1132C43.394 32.1132 44.0624 31.9459 44.492 31.6114C44.9216 31.2769 45.1365 30.8325 45.1365 30.2686C45.1365 29.762 44.9407 29.332 44.5445 28.9831C44.1483 28.6343 43.3367 28.2137 42.1051 27.7311C40.8352 27.2293 39.9378 26.6606 39.4174 26.0155C38.8971 25.3704 38.6345 24.601 38.6345 23.6978C38.6345 22.57 39.0498 21.6764 39.8757 21.0313C40.7016 20.3814 41.8139 20.0612 43.2031 20.0612C44.5922 20.0612 45.8669 20.3431 47.1892 20.9118L46.4636 22.7182C45.2224 22.2116 44.1196 21.9584 43.1458 21.9584C42.4058 21.9584 41.8473 22.116 41.4654 22.4267C41.0835 22.7373 40.8925 23.153 40.8925 23.6644C40.8925 24.018 40.9689 24.3191 41.1217 24.5675C41.2744 24.816 41.5274 25.055 41.8759 25.2748C42.2244 25.4946 42.8546 25.7909 43.7664 26.1541C44.788 26.5698 45.5422 26.9521 46.0196 27.3105C46.497 27.669 46.8455 28.0751 47.0699 28.5243C47.2942 28.9735 47.404 29.504 47.404 30.1157L47.3897 30.1252Z" fill="#404040" />
        <path d="M63.3439 33.7858H61.062V22.1686H56.9613V20.2715H67.4446V22.1686H63.3439V33.7858Z" fill="#404040" />
        <path d="M78.0188 33.7858V20.2715H80.3007V33.7858H78.0188Z" fill="#404040" />
        <path d="M97.2334 33.7858H94.9516V22.1686H90.8508V20.2715H101.334V22.1686H97.2334V33.7858Z" fill="#404040" />
        <path d="M122.84 20.2714V29.0309C122.84 30.0296 122.62 30.9041 122.176 31.6544C121.732 32.4046 121.093 32.9829 120.257 33.3843C119.422 33.7905 118.419 33.9912 117.259 33.9912C115.531 33.9912 114.185 33.5468 113.225 32.6579C112.266 31.7691 111.784 30.5457 111.784 28.9926V20.2666H114.075V28.8349C114.075 29.9532 114.342 30.7751 114.877 31.3055C115.412 31.836 116.233 32.1036 117.336 32.1036C119.484 32.1036 120.558 31.0093 120.558 28.8158V20.2666H122.835L122.84 20.2714Z" fill="#404040" />
        <path d="M139.663 33.7858H137.386V22.1686H133.285V20.2715H143.769V22.1686H139.668V33.7858H139.663Z" fill="#404040" />
        <path d="M161.995 33.7858H153.86V20.2715H161.995V22.14H156.142V25.8196H161.398V27.669H156.142V31.9125H161.995V33.7906V33.7858Z" fill="#404040" />
        <path d="M4.61189 43.3722H3.34205V47.5345H2.58301V43.3722H1.69031V43.0186L2.58301 42.7366V42.4499C2.58301 41.1787 3.11768 40.5432 4.1918 40.5432C4.45436 40.5432 4.76466 40.6005 5.12269 40.7104L4.92697 41.3364C4.63576 41.2409 4.38752 41.1883 4.17747 41.1883C3.89104 41.1883 3.681 41.2887 3.54255 41.4846C3.40411 41.6805 3.33728 41.9959 3.33728 42.4308V42.7653H4.60712V43.3722H4.61189Z" fill="#404040" />
        <path d="M13.4288 44.9441C13.4288 45.7851 13.2236 46.4446 12.813 46.9177C12.4025 47.3908 11.8344 47.625 11.1135 47.625C10.6648 47.625 10.2686 47.5151 9.92485 47.3C9.58113 47.085 9.30902 46.7743 9.12284 46.3682C8.93189 45.962 8.84119 45.4889 8.84119 44.9441C8.84119 44.103 9.04646 43.4436 9.45224 42.9752C9.85801 42.5069 10.4261 42.2728 11.1469 42.2728C11.8678 42.2728 12.4025 42.5117 12.813 42.9944C13.2236 43.477 13.4288 44.1269 13.4288 44.9441ZM9.61932 44.9441C9.61932 45.6036 9.74821 46.1053 10.0012 46.4542C10.2542 46.803 10.6314 46.9751 11.1279 46.9751C11.6243 46.9751 12.0015 46.803 12.2545 46.4589C12.5123 46.1149 12.6412 45.6131 12.6412 44.9489C12.6412 44.2846 12.5123 43.7924 12.2545 43.4531C11.9967 43.1138 11.6196 42.9418 11.1183 42.9418C10.6171 42.9418 10.2495 43.109 9.99645 43.4483C9.74344 43.7828 9.61932 44.2846 9.61932 44.9536V44.9441Z" fill="#404040" />
        <path d="M20.6324 42.2728C20.852 42.2728 21.0525 42.2919 21.2291 42.3301L21.1241 43.0565C20.9188 43.0087 20.7326 42.9848 20.5751 42.9848C20.1693 42.9848 19.8256 43.1568 19.5391 43.4961C19.2527 43.8354 19.1095 44.2607 19.1095 44.7625V47.5342H18.3505V42.3683H18.9758L19.0618 43.3241H19.1C19.2861 42.9896 19.5105 42.7267 19.7683 42.5452C20.0309 42.3636 20.3173 42.2728 20.6324 42.2728Z" fill="#404040" />
        <path d="M35.6557 45.6991C35.6557 46.306 35.4408 46.7791 35.016 47.1184C34.5911 47.4577 34.0135 47.6297 33.2831 47.6297C32.4906 47.6297 31.8843 47.5246 31.4595 47.3143V46.5402C31.7316 46.6597 32.0323 46.7552 32.3522 46.8221C32.672 46.889 32.9919 46.9273 33.3117 46.9273C33.8273 46.9273 34.2187 46.8269 34.4813 46.6214C34.7439 46.4207 34.8728 46.1388 34.8728 45.7756C34.8728 45.5367 34.825 45.3407 34.7343 45.1878C34.6436 45.0349 34.4861 44.8963 34.2713 44.7673C34.0517 44.6382 33.7223 44.4949 33.2783 44.3276C32.6577 44.0983 32.2137 43.8259 31.9512 43.5105C31.6838 43.1951 31.5502 42.7841 31.5502 42.2775C31.5502 41.7471 31.7411 41.3218 32.1278 41.0112C32.5145 40.6958 33.0253 40.5381 33.6602 40.5381C34.2951 40.5381 34.93 40.6623 35.4886 40.9156L35.2451 41.6133C34.6961 41.3744 34.1615 41.2549 33.6411 41.2549C33.2306 41.2549 32.9107 41.3457 32.6768 41.5273C32.4477 41.7089 32.3283 41.9622 32.3283 42.2871C32.3283 42.526 32.3713 42.722 32.4572 42.8749C32.5431 43.0278 32.6863 43.1664 32.8868 43.2954C33.0873 43.4244 33.3976 43.563 33.813 43.716C34.51 43.974 34.9921 44.2512 35.2547 44.5475C35.5172 44.8437 35.6509 45.226 35.6509 45.6991H35.6557Z" fill="#404040" />
        <path d="M42.8261 47.625C42.5015 47.625 42.2055 47.5628 41.9334 47.4386C41.6613 47.3143 41.4369 47.1232 41.2555 46.8651H41.203C41.2412 47.1662 41.2555 47.4529 41.2555 47.7253V50H40.4965V42.3636H41.1123L41.2173 43.0708H41.2555C41.4512 42.7889 41.6756 42.5834 41.9334 42.4591C42.1912 42.3349 42.4871 42.2728 42.8213 42.2728C43.4849 42.2728 43.9957 42.5069 44.3537 42.9752C44.7118 43.4436 44.8932 44.103 44.8932 44.9441C44.8932 45.7851 44.7118 46.4542 44.3442 46.9225C43.9766 47.3908 43.4706 47.625 42.8165 47.625H42.8261ZM42.7163 42.9322C42.2055 42.9322 41.8379 43.0804 41.6088 43.3719C41.3796 43.6634 41.265 44.1317 41.2555 44.7673V44.9441C41.2555 45.6705 41.3701 46.1913 41.6088 46.502C41.8427 46.8126 42.2198 46.9703 42.7354 46.9703C43.165 46.9703 43.504 46.7887 43.7522 46.4303C43.9957 46.0671 44.1198 45.5701 44.1198 44.9345C44.1198 44.299 43.9957 43.7972 43.7522 43.4531C43.5087 43.109 43.165 42.937 42.7211 42.937L42.7163 42.9322Z" fill="#404040" />
        <path d="M53.6531 44.9441C53.6531 45.7851 53.4478 46.4446 53.0373 46.9177C52.6267 47.3908 52.0586 47.625 51.3378 47.625C50.889 47.625 50.4928 47.5151 50.1491 47.3C49.8054 47.085 49.5333 46.7743 49.3471 46.3682C49.1561 45.962 49.0654 45.4889 49.0654 44.9441C49.0654 44.103 49.2707 43.4436 49.6765 42.9752C50.0823 42.5069 50.6503 42.2728 51.3712 42.2728C52.092 42.2728 52.6267 42.5117 53.0373 42.9944C53.4478 43.477 53.6531 44.1269 53.6531 44.9441ZM49.8436 44.9441C49.8436 45.6036 49.9725 46.1053 50.2255 46.4542C50.4785 46.803 50.8556 46.9751 51.3521 46.9751C51.8486 46.9751 52.2257 46.803 52.4787 46.4589C52.7365 46.1149 52.8654 45.6131 52.8654 44.9489C52.8654 44.2846 52.7365 43.7924 52.4787 43.4531C52.2209 43.1138 51.8438 42.9418 51.3426 42.9418C50.8413 42.9418 50.4737 43.109 50.2207 43.4483C49.9677 43.7828 49.8436 44.2846 49.8436 44.9536V44.9441Z" fill="#404040" />
        <path d="M60.3796 42.2728C60.5991 42.2728 60.7996 42.2919 60.9763 42.3301L60.8713 43.0565C60.666 43.0087 60.4798 42.9848 60.3223 42.9848C59.9165 42.9848 59.5728 43.1568 59.2863 43.4961C58.9999 43.8354 58.8567 44.2607 58.8567 44.7625V47.5342H58.0977V42.3683H58.723L58.809 43.3241H58.8471C59.0333 42.9896 59.2577 42.7267 59.5155 42.5452C59.778 42.3636 60.0645 42.2728 60.3796 42.2728Z" fill="#404040" />
        <path d="M67.12 46.9799C67.2537 46.9799 67.3826 46.9703 67.5067 46.9512C67.6308 46.9321 67.7311 46.9082 67.8027 46.8891V47.4864C67.7215 47.5294 67.5974 47.5629 67.4399 47.5868C67.2823 47.6107 67.1343 47.625 67.0102 47.625C66.0459 47.625 65.559 47.0993 65.559 46.0432V42.9705H64.8429V42.593L65.559 42.268L65.8788 41.1641H66.318V42.3636H67.7693V42.9705H66.318V46.0098C66.318 46.3204 66.3896 46.5593 66.5328 46.7266C66.676 46.8938 66.8718 46.9751 67.12 46.9751V46.9799Z" fill="#404040" />
        <path d="M81.4657 43.9215H83.7332V47.2714C83.38 47.386 83.0219 47.4768 82.6591 47.5342C82.2963 47.5915 81.8714 47.625 81.3893 47.625C80.382 47.625 79.5943 47.3144 79.031 46.6931C78.4677 46.0719 78.186 45.2022 78.186 44.0839C78.186 43.3671 78.3245 42.7411 78.6014 42.2011C78.8782 41.6611 79.2792 41.2501 79.8044 40.9682C80.3247 40.6815 80.9405 40.5429 81.6423 40.5429C82.344 40.5429 83.0172 40.6767 83.6282 40.9491L83.3275 41.6563C82.726 41.3935 82.1483 41.2597 81.5898 41.2597C80.7782 41.2597 80.1433 41.5082 79.6898 42.01C79.2363 42.5117 79.0071 43.2046 79.0071 44.0887C79.0071 45.0206 79.2267 45.723 79.6659 46.2057C80.1051 46.6883 80.7496 46.9273 81.5993 46.9273C82.0624 46.9273 82.5111 46.8747 82.9551 46.76V44.6383H81.4657V43.9215Z" fill="#404040" />
        <path d="M93.1279 44.9441C93.1279 45.7851 92.9227 46.4446 92.5121 46.9177C92.1016 47.3908 91.5335 47.625 90.8126 47.625C90.3639 47.625 89.9677 47.5151 89.6239 47.3C89.2802 47.085 89.0081 46.7743 88.8219 46.3682C88.631 45.962 88.5403 45.4889 88.5403 44.9441C88.5403 44.103 88.7456 43.4436 89.1513 42.9752C89.5571 42.5069 90.1252 42.2728 90.846 42.2728C91.5669 42.2728 92.1016 42.5117 92.5121 42.9944C92.9227 43.477 93.1279 44.1269 93.1279 44.9441ZM89.3184 44.9441C89.3184 45.6036 89.4473 46.1053 89.7003 46.4542C89.9533 46.803 90.3305 46.9751 90.8269 46.9751C91.3234 46.9751 91.7006 46.803 91.9536 46.4589C92.2114 46.1149 92.3403 45.6131 92.3403 44.9489C92.3403 44.2846 92.2114 43.7924 91.9536 43.4531C91.6958 43.1138 91.3187 42.9418 90.8174 42.9418C90.3162 42.9418 89.9486 43.109 89.6956 43.4483C89.4425 43.7828 89.3184 44.2846 89.3184 44.9536V44.9441Z" fill="#404040" />
        <path d="M99.143 47.5342L97.2478 42.3684H98.0594L99.1335 45.4315C99.3769 46.1484 99.5201 46.6119 99.5631 46.8269H99.6013C99.6347 46.6597 99.7397 46.3156 99.9164 45.7899C100.093 45.2643 100.494 44.1269 101.115 42.3636H101.926L100.031 47.5294H99.1478L99.143 47.5342Z" fill="#404040" />
        <path d="M108.433 47.625C107.693 47.625 107.11 47.3908 106.685 46.9273C106.256 46.4637 106.046 45.8186 106.046 44.9919C106.046 44.1652 106.246 43.4961 106.642 43.0087C107.039 42.5213 107.573 42.2728 108.242 42.2728C108.867 42.2728 109.363 42.4878 109.726 42.9131C110.089 43.3384 110.275 43.9023 110.275 44.6V45.097H106.833C106.848 45.7039 106.996 46.1627 107.277 46.4781C107.559 46.7935 107.955 46.9512 108.466 46.9512C109.005 46.9512 109.535 46.8365 110.06 46.6023V47.3C109.793 47.4195 109.54 47.5055 109.301 47.5581C109.063 47.6106 108.776 47.6345 108.437 47.6345L108.433 47.625ZM108.227 42.9227C107.826 42.9227 107.506 43.0565 107.268 43.3289C107.029 43.6013 106.886 43.974 106.848 44.4519H109.459C109.459 43.9597 109.354 43.5821 109.139 43.3193C108.924 43.0565 108.624 42.9275 108.227 42.9275V42.9227Z" fill="#404040" />
        <path d="M117.436 42.2728C117.656 42.2728 117.856 42.2919 118.033 42.3301L117.928 43.0565C117.722 43.0087 117.536 42.9848 117.379 42.9848C116.973 42.9848 116.629 43.1568 116.343 43.4961C116.056 43.8354 115.913 44.2607 115.913 44.7625V47.5342H115.154V42.3683H115.78L115.865 43.3241H115.904C116.09 42.9896 116.314 42.7267 116.572 42.5452C116.835 42.3636 117.121 42.2728 117.436 42.2728Z" fill="#404040" />
        <path d="M125.986 47.5342V44.1938C125.986 43.7733 125.895 43.4579 125.709 43.2524C125.523 43.0469 125.232 42.9418 124.84 42.9418C124.32 42.9418 123.933 43.0899 123.69 43.3814C123.447 43.673 123.327 44.1556 123.327 44.8294V47.539H122.568V42.3731H123.184L123.308 43.0804H123.346C123.499 42.8271 123.719 42.6264 124 42.4878C124.282 42.3492 124.592 42.2776 124.936 42.2776C125.537 42.2776 125.991 42.4257 126.296 42.7268C126.602 43.0278 126.755 43.5057 126.755 44.1652V47.5342H125.996H125.986Z" fill="#404040" />
        <path d="M134.966 47.5342L134.813 46.7983H134.775C134.527 47.1232 134.278 47.343 134.03 47.4577C133.782 47.5724 133.472 47.6298 133.104 47.6298C132.608 47.6298 132.221 47.496 131.939 47.2331C131.658 46.9703 131.519 46.5928 131.519 46.1053C131.519 45.0636 132.326 44.514 133.94 44.4662L134.789 44.4376V44.1174C134.789 43.7112 134.703 43.4149 134.536 43.219C134.369 43.0231 134.097 42.9275 133.725 42.9275C133.309 42.9275 132.837 43.0613 132.312 43.3241L132.078 42.7268C132.326 42.5882 132.593 42.4783 132.889 42.4018C133.18 42.3254 133.476 42.2823 133.772 42.2823C134.369 42.2823 134.808 42.4209 135.095 42.6933C135.381 42.9657 135.524 43.4054 135.524 44.0075V47.5342H134.966ZM133.257 46.9846C133.729 46.9846 134.097 46.8508 134.369 46.5832C134.641 46.3156 134.775 45.9429 134.775 45.4602V44.9919L134.016 45.0253C133.414 45.0492 132.98 45.1448 132.713 45.3169C132.445 45.4889 132.312 45.7565 132.312 46.1149C132.312 46.3968 132.393 46.6119 132.56 46.76C132.727 46.9082 132.956 46.9799 133.257 46.9799V46.9846Z" fill="#404040" />
        <path d="M144.098 47.5342V44.1938C144.098 43.7733 144.008 43.4579 143.821 43.2524C143.635 43.0469 143.344 42.9418 142.953 42.9418C142.432 42.9418 142.045 43.0899 141.802 43.3814C141.559 43.673 141.439 44.1556 141.439 44.8294V47.539H140.68V42.3731H141.296L141.42 43.0804H141.458C141.611 42.8271 141.831 42.6264 142.112 42.4878C142.394 42.3492 142.704 42.2776 143.048 42.2776C143.649 42.2776 144.103 42.4257 144.409 42.7268C144.714 43.0278 144.867 43.5057 144.867 44.1652V47.5342H144.108H144.098Z" fill="#404040" />
        <path d="M152.004 47.6249C151.278 47.6249 150.719 47.3955 150.323 46.932C149.927 46.4685 149.726 45.8186 149.726 44.9775C149.726 44.1364 149.927 43.4435 150.333 42.9752C150.738 42.5069 151.311 42.2679 152.056 42.2679C152.295 42.2679 152.538 42.2966 152.777 42.3492C153.016 42.4018 153.207 42.4639 153.34 42.5356L153.106 43.1998C152.939 43.1329 152.758 43.0756 152.557 43.0278C152.362 42.9848 152.185 42.9609 152.032 42.9609C151.015 42.9609 150.509 43.6299 150.509 44.9679C150.509 45.6035 150.633 46.0909 150.882 46.4302C151.13 46.7695 151.498 46.9416 151.984 46.9416C152.4 46.9416 152.829 46.8508 153.264 46.6644V47.3573C152.93 47.5341 152.51 47.6249 151.999 47.6249H152.004Z" fill="#404040" />
        <path d="M160.157 47.625C159.417 47.625 158.835 47.3908 158.41 46.9273C157.985 46.4637 157.77 45.8186 157.77 44.9919C157.77 44.1652 157.971 43.4961 158.367 43.0087C158.763 42.5213 159.298 42.2728 159.966 42.2728C160.592 42.2728 161.088 42.4878 161.451 42.9131C161.814 43.3384 162 43.9023 162 44.6V45.097H158.558C158.572 45.7039 158.72 46.1627 159.002 46.4781C159.284 46.7935 159.68 46.9512 160.191 46.9512C160.73 46.9512 161.26 46.8365 161.785 46.6023V47.3C161.518 47.4195 161.265 47.5055 161.026 47.5581C160.787 47.6106 160.501 47.6345 160.162 47.6345L160.157 47.625ZM159.952 42.9227C159.551 42.9227 159.231 43.0565 158.992 43.3289C158.754 43.6013 158.615 43.974 158.572 44.4519H161.184C161.184 43.9597 161.079 43.5821 160.864 43.3193C160.649 43.0565 160.348 42.9275 159.952 42.9275V42.9227Z" fill="#404040" />
        <path d="M6.7502 13.5143C10.4782 13.5143 13.5004 10.489 13.5004 6.75715C13.5004 3.02528 10.4782 0 6.7502 0C3.02217 0 0 3.02528 0 6.75715C0 10.489 3.02217 13.5143 6.7502 13.5143Z" fill="#EA5C60" />
        <path d="M27.0009 13.5143C30.729 13.5143 33.7511 10.489 33.7511 6.75715C33.7511 3.02528 30.729 0 27.0009 0C23.2729 0 20.2507 3.02528 20.2507 6.75715C20.2507 10.489 23.2729 13.5143 27.0009 13.5143Z" fill="#A7A6A6" />
        <path d="M47.2517 13.5143C50.9797 13.5143 54.0019 10.489 54.0019 6.75715C54.0019 3.02528 50.9797 0 47.2517 0C43.5236 0 40.5015 3.02528 40.5015 6.75715C40.5015 10.489 43.5236 13.5143 47.2517 13.5143Z" fill="#FABA4A" />
      </svg>
    </NavLink>
  );
}

export default Logo;
