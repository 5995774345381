import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input/Input';
import { useState } from 'react';
import jsonp from 'jsonp';

function SubscribeForm() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const onSubmit = () => {
    const url = 'https://govsport.us21.list-manage.com/subscribe/post-json?u=cfb3b51d64b7e4b3d75fca194&amp;id=ea76085deb&amp;f_id=00b75ee1f0';
    jsonp(`${url}&EMAIL=${email}&tags=2818997`, { param: 'c' }, (_, data) => {
      const {
        msg, result,
      } = data;

      if (result === 'error') {
        setMessage(msg.slice(4));
      }

      if (result === 'success') {
        setMessage(msg);
        setEmail('');
      }
    });
  };

  return (
    <form className="my-10" onSubmit={(event) => event.preventDefault()}>
      <div className="flex rounded overflow-hidden">
        <Input
          onChange={(event) => setEmail(event.target.value)}
          name="EMAIL"
          placeholder={t('forms.email')}
          value={email}
          fullWidth
          type="email"
        />
        <Button
          type="submit"
          onClick={onSubmit}
        >
          {t('forms.subscribe')}
        </Button>
      </div>
      {message.length !== 0 && (
        <p className="text-xs text-white uppercase w-full block mt-2.5">
          {message}
        </p>
      )}
    </form>
  );
}

export default SubscribeForm;
