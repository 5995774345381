import {
  DownloadResources,
  Founded,
  Hero,
  Partners,
} from 'components/_domains/ActionPage';

function ActionPage() {
  return (
    <>
      <Hero />
      <DownloadResources />
      <Partners />
      <Founded />
    </>
  );
}

export default ActionPage;
