import Logo from 'components/Logo/Logo';
import Navigation from 'components/Navigation/Navigation';

function Header() {
  return (
    <header className="bg-white relative">
      <div className="container px-3.5 py-7 flex flex-wrap justify-between items-center">
        <Logo />
        <Navigation />
      </div>
    </header>
  );
}

export default Header;
