export const enLocale = {
  navigation: {
    home: 'Home',
    aboutUs: 'About Us',
    projects: 'Projects',
    blog: 'Blog',
    contact: 'Contact',
    team: 'Team',
  },
  footer: {
    title: 'Stay tuned',
    description: 'Stay up to date with the latest news and updates.',
    followUs: 'follow us',
    copyright: '© 2023 Institute for Sport Governance. All rights reserved.',
  },
  forms: {
    email: 'e-mail address',
    subscribe: 'subscribe',
  },
  actionPage: {
    hero: {
      title: 'Action',
      subTitle: 'GOVERNANCE SPORT CODIFICATION CONVERGENCE',
      description: 'Institute for Sport Governance and the partners of the project “Governance Sport Codification Convergence – Action” proudly present an interactive, educational platform based on the European Convergent Code of Good Governance. The Code itself was drafted by the partners of the project and is based on the extensive 3-year research, which included  multiple surveys, reports and analysis of scientific data.',
      secondDescription: 'The platform allows users to explore various aspects of good governance – their meaning, scope and way to achieve positive results within their organisations. Each element has been assigned a short educational video with automatically generated subtitles. There is no definite order of getting around the platform, but we encourage users to start with the central “Code” icon and then explore the various areas of good governance according to their individual needs. Remember that the complete content of the platform and the project’s research is available in the “Resources” section.',
    },
    circle: {
      basketball: {
        title: 'responsible federation',
        tooltipText: 'responsible federation',
        tooltipHelper: 'Click the "+" to reveal more details',
        videoTitle: 'Watch video to learn more about this practice:',
        items: [
          'The federation educates on anti‐doping and anti-match‐fixing.',
          'The federation promotes fair treatment and inclusion for all its stakeholders.',
          'The federation implements a policy to avoid any form of harassment and abuse.',
          'The federation implements a policy on the development and well-being of its athletes.',
          'The federation ensures its environmental sustainability.',
        ],
      },
      volleyball: {
        title: 'effective general assembly',
        tooltipText: 'effective general assembly',
        tooltipHelper: 'Click the "+" to reveal more details',
        videoTitle: 'Watch video to learn more about this practice:',
        items: [
          'Members of the Federation meet at least once a year at the General Assembly.',
          'Federation’s General Assembly supervises the Board’s performance.',
          'Federation publishes the agenda and minutes of the General Assembly on its website.',
        ],
      },
      other: {
        title: 'accountable federation',
        tooltipText: 'accountable federation',
        tooltipHelper: 'Click the "+" to reveal more details',
        videoTitle: 'Watch video to learn more about this practice:',
        items: [
          'Federation has a clear structure that defines the role of its Board members in contrast to the role of its paid staff.',
          'Federation publishes its updated statutes, policies, strategies, and internal regulations on its website.',
          'Federation implements an external financial control system.',
          'Federation publishes annual reports, including financial statements, on its website ',
        ],
      },
      football: {
        title: 'Active Board',
        tooltipText: 'Active Board',
        tooltipHelper: 'Click the "+" to reveal more details',
        videoTitle: 'Watch video to learn more about this practice:',
        items: [
          'Board members of the Federation hold regular meetings.',
          'Federation’s Board supervises paid staff.',
          'Board members regularly consult committees consisting of representatives of the key stakeholders.',
          'Federation publishes Board decisions on its website.',
        ],
      },
      tennis: {
        title: 'Legitimate board',
        tooltipText: 'Legitimate board',
        tooltipHelper: 'Click the "+" to reveal more details',
        videoTitle: 'Watch video to learn more about this practice:',
        items: [
          'Federation establishes clear conflict of interest procedures for the Board members. (Eligibility for board election)',
          'Federation ensures a differentiated and balanced composition of its Board.',
          'Board members of Federation are democratically (re‐) appointed, according to clear and transparent procedures, including term limit.',
          'Federation publishes Board members’ background information on its website.',
        ],
      },
      documents: {
        title: 'European Convergent Code of Good Governance',
        tooltipText: 'European Convergent Code of Good Governance',
        tooltipHelper: 'Click the "+" to reveal more details',
        videoTitle: 'Watch video to learn more about the Code:',
        items: [],
      },
    },
    downloadResources: {
      title: 'download resources',
      items: [
        'Baseline report',
        'Best Practices Report',
        'Converged Code - English',
        'Converged Code - Bulgarian',
        'Converged Code - French',
        'Converged Code - German',
        'Converged Code - Greek',
        'Converged Code - Lithuanian',
        'Converged Code - Maltese',
        'Converged Code - Polish',
        'Action Converged Code - English',
        'Action Converged Code - Bulgarian',
        'Action Converged Code - French',
        'Action Converged Code - German',
        'Action Converged Code - Greek',
        'Action Converged Code - Lithuanian',
        'Action Converged Code - Maltese',
        'Action Converged Code - Polish',
      ],
    },
    partners: {
      title: 'Partners',
    },
    founded: {
      disclaimer: 'Disclaimer',
      description: 'The European Commission support for the production of this publication does not imply endorsement of the content which reflects the views only of the authors, and the Commission cannot be held responsible for any use that may be made of the information contained therein.',
    },
  },
  cookies: {
    content: 'This site uses cookies to provide services at the highest level. By continuing to use the site, you agree to their use.',
    agreeButton: 'Agree',
    privacyPolicyButton: 'Privacy policy',
  },
  globalLabels: {
    showMore: 'Show more',
    showLess: 'Show less',
  },
};
