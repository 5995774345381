import {
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import LangEnglishIcon from 'assets/images/lang-en.png';
import LangPolishIcon from 'assets/images/lang-pl.png';

function LanguageSwitcher() {
  const [isOpen, setOpen] = useState(false);
  const {
    i18n,
  } = useTranslation();

  const LANG_ICONS = {
    en: LangEnglishIcon,
    pl: LangPolishIcon,
  };

  const handleChangeLanguage = (lang, event) => {
    event.stopPropagation();
    i18n.changeLanguage(lang);
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', () => {
      setOpen(false);
    });
  }, []);

  return (
    <div className="relative">
      <button
        type="button"
        className="px-3.5"
        onClick={(event) => {
          event.stopPropagation();
          setOpen((prevState) => !prevState);
        }}
      >
        <img src={LANG_ICONS[i18n.language]} alt={`language switch ${i18n.language}`} />
      </button>
      <ul className={`${isOpen ? 'block' : 'hidden'} absolute left-0 right-0 bg-action-light-gray shadow py-2.5 z-50`}>
        <li className="hover:bg-white py-2.5 px-3.5">
          <button type="button" className="btn" onClick={(event) => handleChangeLanguage('en', event)}>
            <img src={LangEnglishIcon} alt="language switch en" />
          </button>
        </li>
        <li className="hover:bg-white py-2.5 px-3.5">
          <button type="button" className="btn" onClick={(event) => handleChangeLanguage('pl', event)}>
            <img className="w-full" src={LangPolishIcon} alt="language switch pl" />
          </button>
        </li>
      </ul>
    </div>
  );
}

export default LanguageSwitcher;
