import { CIRCLE_ELEMENTS_OPTION } from 'constants/index';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HeroImage } from 'assets/images/action-page-hero.svg';
import HeadingWithDecor from 'components/HeadingWithDecor/HeadingWithDecor';
import { Graphic } from 'components/_domains/ActionPage/index';
import { useState } from 'react';
import DropdownAccordion from 'components/_domains/ActionPage/DropdownAccordion/DropdownAccordion';
import VideoItem from 'components/_domains/ActionPage/VideoItem/VideoItem';

function Hero() {
  const { t } = useTranslation();
  const [currentElement, setCurrentElement] = useState('');
  const [currentVideoSrc, setCurrentVideoSrc] = useState(null);

  const OPTION = currentElement in CIRCLE_ELEMENTS_OPTION ? CIRCLE_ELEMENTS_OPTION[currentElement] : {};
  const isElementSelected = Object.values(OPTION).length !== 0;

  return (
    <section id="action-page-hero" className="relative pb-14 lg:pb-44 bg-action-light-gray pt-14">
      <div className="bg-action-anti-flash-white absolute left-0 inset-y-0 w-16 md:w-48 2xl:w-60 z-0" />
      <HeroImage className="absolute right-0 bottom-0 z-0" />
      <div className="container lg:px-3.5 lg:relative">
        <div className={`${isElementSelected ? 'hidden lg:grid' : ''} grid grid-cols-1 md:grid-cols-2 gap-3.5 px-3.5 lg:px-0`}>
          <div>
            <HeadingWithDecor title={t('actionPage.hero.title')} />
            <p className="text-xl font-medium uppercase text-action-onyx w-full relative">
              {t('actionPage.hero.subTitle')}
            </p>
          </div>
          <p className="text-base font-medium text-action-onyx w-full relative mb-4 text-justify">
            {t('actionPage.hero.description')}
          </p>
        </div>
        <div className={`${isElementSelected ? 'hidden lg:flex' : ''} flex px-3.5 lg:px-0 justify-start`}>
          <p className="text-base font-medium text-action-onyx w-full relative text-justify flex max-w-[100%]">
            {t('actionPage.hero.secondDescription')}
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-3.5 -mt-14 lg:mt-0">
          <div className="flex flex-wrap items-center">
            {isElementSelected && (
              <DropdownAccordion
                dropdownData={OPTION}
                handleCloseElement={() => setCurrentElement(null)}
                setCurrentVideoSrc={setCurrentVideoSrc}
              />
            )}
          </div>
          <div className={`${isElementSelected ? 'hidden lg:flex' : ''} flex flex-wrap justify-center py-14`}>
            <Graphic handleCurrentElement={setCurrentElement} currentElement={currentElement} />
          </div>
          <div className="hidden lg:flex items-center w-full">
            {isElementSelected && (
              <VideoItem videoSrc={currentVideoSrc || OPTION.dropdown.videoSrc} title={OPTION.dropdown.videoTitle} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
