export const plLocale = {
  navigation: {
    home: 'Strona Główna',
    aboutUs: 'O Fundacji',
    projects: 'Projekty',
    blog: 'Blog',
    contact: 'Kontakt',
    team: 'Zespół',
  },
  footer: {
    title: 'Stay tuned',
    description: 'Stay up to date with the latest news and updates. Our newsletter is sent once a week, every Monday.',
    followUs: 'follow us',
    copyright: '© 2023 Institute for Sport Governance. All rights reserved.',
  },
  forms: {
    email: 'e-mail address',
    subscribe: 'subscribe',
  },
  actionPage: {
    hero: {
      title: 'Action',
      subTitle: 'Convergent code of good governance',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pellentesque ultricies mi at aliquet. Quisque non gravida ligula, nec porta urna. Proin tempor sit amet lacus vel posuere. Phasellus euismod feugiat eleifend. Quisque non gravida ligula, nec porta urna. Proin tempor sit amet lacus vel posuere.',
    },
    downloadResources: {
      title: 'download resources',
      items: [
        'Lorem ipsum dolor sit amet, consectetur',
        'Sed et dui sed neque tincidunt commodo',
        'Maecenas iaculis nulla odio, a tincidunt arcu lacinia',
        'Vivamus faucibus, nulla nec congue pharetra',
      ],
    },
    partners: {
      title: 'Partners',
    },
    founded: {
      disclaimer: 'Disclaimer',
      description: 'The European Commission support for the production of this publication does not imply endorsement of the content which reflects the views only of the authors, and the Commission cannot be held responsible for any use that may be made of the information contained therein.',
    },
  },
};
