import { useTranslation } from 'react-i18next';
import { ReactComponent as EULogo } from 'assets/images/eu-logo.svg';
import Badge from 'components/Badge/Badge';

function Founded() {
  const { t } = useTranslation();

  return (
    <section id="action-page-founded" className="bg-action-light-gray py-24">
      <div className="container px-3.5 grid grid-cols-1 lg:grid-cols-12 lg:gap-x-5 md:gap-x-14 gap-y-5">
        <div className="col-span-5 flex items-center lg:border-r lg:border-action-onyx lg:border-solid">
          <EULogo className="w-[250px] h-[53px] md:w-[349px] md:h-[74px]" />
        </div>
        <div className="col-span-7">
          <Badge>
            {t('actionPage.founded.disclaimer')}
          </Badge>
          <p className="mt-5">
            {t('actionPage.founded.description')}
          </p>
        </div>
      </div>
    </section>
  );
}

export default Founded;
