import PropTypes from 'prop-types';

function Input({
  name,
  onChange,
  value,
  placeholder,
  fullWidth,
  type,
}) {
  return (
    <input
      name={name}
      onChange={onChange}
      value={value}
      className={`border-2 border-white border-solid bg-action-pink py-4 px-3 text-sm text-white placeholder-white outline-0 ${fullWidth ? 'w-full' : ''}`}
      placeholder={placeholder}
      type={type}
    />
  );
}

export default Input;

Input.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
};

Input.defaultProps = {
  fullWidth: false,
  type: 'text',
};
