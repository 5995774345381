import { useTranslation } from 'react-i18next';
import FooterBg from 'assets/images/footer-bg.png';
import HeadingWithDecor from 'components/HeadingWithDecor/HeadingWithDecor';
import { ReactComponent as FooterFb } from 'assets/images/footer-fb.svg';
import { ReactComponent as FooterTwitter } from 'assets/images/footer-twitter.svg';
import SubscribeForm from 'components/SubscribeForm/SubscribeForm';
import Cookies from 'components/Cookies/Cookies';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer
      className="bg-action-pink pb-10 pt-20 relative bg-no-repeat bg-left-bottom"
      style={{
        backgroundImage: `url("${FooterBg}")`,
      }}
    >
      <div className="bg-action-anti-flash-white hidden lg:block absolute right-0 inset-y-0 w-16 md:w-48 2xl:w-60 z-0" />
      <div className="container px-3.5 grid grid-cols-1 lg:grid-cols-2 gap-5 relative">
        <div>
          <HeadingWithDecor title={t('footer.title')} decorColor="bg-white" headingColor="text-white" />
          <p className="text-base text-white mt-5">
            {t('footer.description')}
          </p>
          <div className="w-full max-w-[505px]">
            <SubscribeForm />
          </div>
        </div>
        <div className="max-w-[190px] w-full">
          <h3 className="text-white uppercase text-xl mb-5 pt-5 border-t border-white border-solid">
            {t('footer.followUs')}
          </h3>
          <div className="flex flex-wrap gap-2.5">
            <a href="https://www.facebook.com/ACTIONSportGov" rel="noreferrer" target="_blank">
              <FooterFb />
            </a>
            <a href="https://twitter.com/ACTIONSportGov" rel="noreferrer" target="_blank">
              <FooterTwitter />
            </a>
          </div>
        </div>
        <div className="w-full flex justify-start lg:justify-center mt-10 md:col-span-2">
          <p className="text-white text-left md:text-center">
            {t('footer.copyright')}
          </p>
        </div>
      </div>
      <Cookies />
    </footer>
  );
}

export default Footer;
