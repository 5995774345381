// Documents for resources section
import BaselineReport from 'constants/_domains/documents/Action-baseline-report.pdf';
import BestPracticesReport from 'constants/_domains/documents/Best-Practices-Report.pdf';
import InfographicBulgarian from 'constants/_domains/documents/Infographic-Bulgarian.pdf';
import InfographicBulgarianV2 from 'constants/_domains/documents/Infographic-Bulgarian-v2.pdf';
import InfographicEnglish from 'constants/_domains/documents/Infographic-English.pdf';
import InfographicEnglishV2 from 'constants/_domains/documents/Infographic-English-v2.pdf';
import InfographicFrench from 'constants/_domains/documents/Infographic-French.pdf';
import InfographicFrenchV2 from 'constants/_domains/documents/Infographic-French-v2.pdf';
import InfographicGerman from 'constants/_domains/documents/Infographic-German.pdf';
import InfographicGermanV2 from 'constants/_domains/documents/Infographic-German-v2.pdf';
import InfographicGreek from 'constants/_domains/documents/Infographic-Greek.pdf';
import InfographicGreekV2 from 'constants/_domains/documents/Infographic-Greek-v2.pdf';
import InfographicLithuanian from 'constants/_domains/documents/Infographic-Lithuanian.pdf';
import InfographicLithuanianV2 from 'constants/_domains/documents/Infographic-Lithuanian-v2.pdf';
import InfographicMaltese from 'constants/_domains/documents/Infographic-Maltese.pdf';
import InfographicMalteseV2 from 'constants/_domains/documents/Infographic-Maltese-v2.pdf';
import InfographicPolish from 'constants/_domains/documents/Infographic-Polish.pdf';
import InfographicPolishV2 from 'constants/_domains/documents/Infographic-Polish-v2.pdf';

import Partner1 from 'assets/images/partners/logo-1.png';
import Partner2 from 'assets/images/partners/logo-2.png';
import Partner3 from 'assets/images/partners/logo-3.png';
import Partner4 from 'assets/images/partners/logo-4.png';
import Partner5 from 'assets/images/partners/logo-5.png';
import Partner6 from 'assets/images/partners/logo-6.png';
import Partner7 from 'assets/images/partners/logo-7.png';

export const downloadResources = [
  {
    id: 'oU4uG1nKi1JTUj74',
    link: BaselineReport,
  },
  {
    id: 'YiPNIz6eWrMGYqw9',
    link: BestPracticesReport,
  },
  {
    id: 'sh5vf3RCYRvY43hB',
    link: InfographicEnglishV2,
  },
  {
    id: 'u7GSl0ZZgRDo1i2v',
    link: InfographicBulgarianV2,
  },
  {
    id: 't6ytch49uXMxCrgR',
    link: InfographicFrenchV2,
  },
  {
    id: '28s6p5aDNKaUvV5X',
    link: InfographicGermanV2,
  },
  {
    id: 'ttyMQPLVOs7xj0V9',
    link: InfographicGreekV2,
  },
  {
    id: 'o2YT6CxrL7AVXSNT',
    link: InfographicLithuanianV2,
  },
  {
    id: 'a20YdB26ks19r1DM',
    link: InfographicMalteseV2,
  },
  {
    id: 'Q8m5oK0y5W0K5XZm',
    link: InfographicPolishV2,
  },
  {
    id: 'efJyOjE5J6oO32Fx',
    link: InfographicEnglish,
  },
  {
    id: 'Y2JXVL42Ea7c756s',
    link: InfographicBulgarian,
  },
  {
    id: 'x92cd4JjT1wd7C3i',
    link: InfographicFrench,
  },
  {
    id: 'MOOLYExk0IewDnoI',
    link: InfographicGerman,
  },
  {
    id: 'kIENp3T4ArU39SZq',
    link: InfographicGreek,
  },
  {
    id: '8b0Quri047Kd48qN',
    link: InfographicLithuanian,
  },
  {
    id: 'kldG8HQ1Adecuce1',
    link: InfographicMaltese,
  },
  {
    id: 'te7NX93N2VSYFp0X',
    link: InfographicPolish,
  },
];

export const partners = [
  {
    id: 's73RI6ihTs94buum',
    image: Partner1,
    link: 'https://www.uclancyprus.ac.cy/',
  },
  {
    id: 'DYcECykj7scE057I',
    image: Partner2,
    link: 'https://www.lunex-university.net/',
  },
  {
    id: '3nShwrfl40M7A029',
    image: Partner3,
    link: 'http://mpes.government.bg/',
  },
  {
    id: 'JZIXU91guMHH9Pu8',
    image: Partner4,
    link: 'http://www.lsfs.lt/',
  },
  {
    id: 'dQkhx303zp3pxkco',
    image: Partner5,
    link: '',
  },
  {
    id: 'Oh8bgjQ25B77r18O',
    image: Partner6,
    link: 'https://cyprussports.org/gr/',
  },
  {
    id: '939X2xqWy46N61pk',
    image: Partner7,
    link: '',
  },
];
